import React, {useState, useEffect, useRef} from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from "../styles/about.module.scss"

const About = ({about, aboutModal}) => {
  const {experiences, commissioners, collaborators, global} = useStaticQuery(graphql`
    {
      experiences: allExperiencesJson {
        edges {
          node {
            section
            type
            entries {
              year
              text
              url
            }
          }
        }
      }
      commissioners: allCommissionersJson {
        edges {
          node {
            entries
          }
        }
      }
      collaborators: allCollaboratorsJson {
        edges {
          node {
            section
            personns {
              name
              url
            }
          }
        }
      }
      global: allGlobalJson {
        edges {
          node {
            teaching
            town
            mail
            social {
              name
              url
            }
            bio
          }
        }
      }
    }
  `);
  
  const [legalNotice, setLegalNotice] = useState(false)

  const firstColumn = experiences.edges.filter(({node}) => node.type === "experience")
  const secondColumn = experiences.edges.filter(({node}) => node.type === "teaching")
  const texts = global.edges[0].node
  let commissionersList = commissioners.edges[0].node.entries
  commissionersList.sort(function (a, b) {
    a = a.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    b = b.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    if (a < b) {
        return -1;
    }
    if (b < a) {
        return 1;
    }
    return 0;
  });

  const aboutId = useRef()
  const handleClickOutside = e => {
    if (aboutId.current.contains(e.target)){
      return
    } 
    aboutModal()
  }

  useEffect( () => {
      if(about){
          document.addEventListener("mousedown", handleClickOutside)
      } else {
          document.removeEventListener("mousedown", handleClickOutside)
          
      }
      return () => {
          document.removeEventListener("mousedown", handleClickOutside)
      }
     
  }, [about])

  
  return (
    <div className={`${styles.wrapper} ${about ? styles.visible : ""}`} ref={aboutId}>
      <div className={styles.header} onTouchStart={() => aboutModal() }>
        <div><a href={`mailto:${texts.mail}`} title="contact me">{texts.mail}</a></div>
        <div>
          {texts.social.map( (entry, index) => {
            return (
              <>
                <a href={entry.url} title={entry.name} target="blank">{entry.name}</a>{index +1 < texts.social.length ? ", " : ""}
              </>
            )
          })}
        </div>
        <div><a href="http://eepurl.com/hXKQyX" target="blank" >Join the newsletter</a></div>
        <div>Portfolio on request</div>
      </div>

      <div className={styles.container}>
        <div className={styles.column}>
            <h4>Bio</h4>
            <p>Salomé Macquet (b. 1993) is a French artist and designer. She graduated from the École nationale supérieure des Arts décoratifs in Paris in mid-2016, where she started to draw the concept of time. She now pursues research focused on time and its relationship within physics, political &#38; social sciences through speculative design, graphic design and performance.</p>
            <p>In addition to independent research projects, Salomé has been working on commissioned projects for cultural institutions, science &#38; tech companies, architectural firms… Scope includes designing books, websites, identities, typefaces and posters as well as coding websites and algorithms. </p>
            <p>Since 2023, she is co-director of the Opus Art faber collection.</p>
            <p>In 2019, she has been selected to design the French Hommage to Bauhaus 100 years at the French Embassy in Berlin. Since then, her work is part of the French State Collections.</p>
        </div>
        <div className={styles.column}>
          {firstColumn.map(({node}) => {
            return (
              <>
                <h4>{node.section}</h4>
                {node.entries.map((entry) => {
                  return (
                    <div className={styles.entry}>
                        <div>{entry.year}</div>
                        <div>
                          {entry.url ? 
                            <a href={entry.url} title={entry.text} target="blank">{entry.text}</a>
                          :
                            <>{entry.text}</>
                          }
                        </div>
                    </div>
                  )
                })}
              </>
            )
          })}
        </div>
        <div className={styles.column}>
          <h4>Teaching</h4>
          {texts.teaching}
          {secondColumn.map(({node}) => {
              return (
                <>
                  <h5>{node.section}</h5>
                  {node.entries.map((entry) => {
                    return (
                      <div className={styles.entry}>
                        <div>{entry.year}</div>
                        <div>
                          {entry.url ? 
                            <a href={entry.url} title={entry.text} target="blank">{entry.text}</a>
                          :
                            <>{entry.text}</>
                          }
                        </div>
                      </div>
                    )
                  })}
                </>
              )
            })}
            {collaborators.edges.map(({node}) => {
                    return (
                      <>
                        <h5>{node.section}</h5>
                        {node.personns.map((entry) => {
                          return (
                            <div className={styles.entry}>
                              <div></div>
                              <div><a href={entry.url} title={entry.name} target="blank">{entry.name}</a></div>
                            </div>
                          )
                        })}
                      </>
                    )
                  })}
        </div>
        <div className={styles.column}>
          <h4>commissioners (selection)</h4>
          {commissionersList.map( (entry) => {
            return (
              <>
                <div>{entry}</div>
              </>
            )
          })
          }
        </div>

        <div className={styles.footer}>
          (c)<br></br> 2016 → {new Date().getFullYear() }<br></br>Salomé Macquet, all rights reserved
          <p class={ styles.legal } onClick={ () => { setLegalNotice(!legalNotice)}}>Legal notice</p>
          { legalNotice && 
            <>
              Salomé Macquet<br />
              bonjour@salomemacquet.com
              
              <p>Hosting by OVH SAS 2 rue Kellermann - 59100 Roubaix - France</p>

            </>
          }
        </div>
      </div>
    </div>
  )
}

export default About